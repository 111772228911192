<style lang="less" scoped>
	img {
		width: 100%;
		height: 100%;
	}

	.over {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.w {
		width: 1200px;
		margin: 0 auto;
	}

	.one {
		padding-top: 50px;

		// background-color: #f8f8f8;
		.cannot {
			margin-bottom: 60px;
			width: 1200px;
			height: 120px;
			color: #333333;
			background-color: rgba(0, 144, 240, 0.15);

			div {
				font-size: 20px;
				font-weight: 500;

				&:nth-child(2) {
					cursor: pointer;
					font-size: 14px;
					margin-top: 15px;
				}
			}
		}
	}

	.play_header {
		color: #333333;
		font-size: 26px;
		font-weight: 700;
	}

	.play_list {
		flex-wrap: wrap;
		padding-bottom: 20px;

		.play_item {
			position: relative;
			top: 0px;
			// cursor: pointer;
			margin-top: 20px;
			margin-bottom: 20px;
			// padding: 15px 20px 15px;
			margin-right: 26px;
			width: 280px;
			height: 270px;
			background-color: #ffffff;
			box-sizing: border-box;
			border-radius: 6px;
			overflow: hidden;
			transition: all 0.5s;

			&:nth-child(4n) {
				margin-right: 0;
			}

			&:hover {
				position: relative;
				// top: -5px;
				box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);

				.P_detail1 {
					box-shadow: 0px 3px 5px 0px rgba(199, 0, 0, 0.34);
					background: #f13232;
					color: #fff !important;
					cursor: pointer;
				}
			}

			.P_tag {
				width: 70px;
				height: 24px;
				text-align: center;
				line-height: 24px;
				font-size: 12px;
				color: #333333;
				background-color: #f2f2f2;
				border-radius: 12px;
				box-sizing: border-box;
			}

			.P_tag1 {
				color: #fff;
				background-color: #0090f0;
			}

			.P_title {
				// margin-top: 15px;
				// height: 60px;
				font-size: 20px;
				font-weight: 500;
				color: #333333;
				line-height: 45px;
				margin: 0 17px;
				// line-height: 30px;
			}

			.P_time {
				color: #999999;
				font-size: 12px;
				margin-top: 30px;
			}

			.P_teacher {
				margin-top: 12px;
				padding-bottom: 25px;
				border-bottom: 1px solid #f2f2f2;
				color: #999999;
				font-size: 12px;
			}

			.P_top {
				height: 195px;

				// background-color: skyblue;
				.P_top_img {
					width: 100%;
					height: 170px;
					// background-color: slategray;
				}
			}

			.P_bottom {
				justify-content: space-between;
				height: 45px;
				margin: 10px 17px;

				.P_free {
					font-size: 22px;
					font-weight: 500;
					color: #f13232;
				}

				.P_detail {
					width: 100px;
					height: 30px;
					line-height: 30px;
					text-align: center;
					color: #f13232;
					border: 1px solid #f13232;
					border-radius: 15px;
				}
			}
		}
	}

	.P_bottom_num {
		font-size: 12px;
		color: #999999;

		span {
			color: #f13232;
		}
	}

	.two {
		padding: 50px 0;

		// background-color: #fff;
		&:nth-child(2n) {
			// background-color: #f8f8f8;
		}
	}

	// .D_left {
	//    width: 250px;
	// }
	.D_name {
		width: 250px;
		height: 35px;
		font-size: 26px;
		font-weight: bold;
		color: #333333;
	}

	.D_img {
		margin-top: 20px;
		width: 250px;
		height: 430px;
	}

	// .D_right {
	//   margin-left: 20px;
	//   width: 950px;
	// }
	.D_Tabs {
		margin-left: 20px;

		div {
			cursor: pointer;
			height: 35px;
			line-height: 35px;
			margin-right: 50px;
			font-size: 16px;
			font-weight: 500;
			color: #333333;
		}

		.tabItem {
			height: 35px;
			color: #0090f0;
			position: relative;
		}

		.tabItem::after {
			position: absolute;
			content: "";
			height: 2px;
			width: 30px;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			background-color: #0090f0;
		}
	}

	.midRight {
		justify-content: space-between;
	}

	.mid {
		flex-wrap: wrap;
		width: 660px;
		height: 450px;

		.midItem {
			cursor: pointer;
			margin-top: 20px;
			margin-right: 22px;
			width: 205px;
			height: 205px;
			overflow: hidden;
			border-radius: 6px;

			// background-color: #fff;
			.itemImg {
				width: 205px;
				height: 125px;
			}

			.iconfont {
				font-size: 30px;
			}

			.title {
				margin-top: 15px;
				padding: 0 15px;
				font-weight: 500;
				line-height: 22px;
			}

			.info {
				margin-top: 5px;
				padding: 0 15px;
				justify-content: space-between;
				font-size: 12px;
				color: #999;

				span {
					color: #f13232;
				}
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	.right {
		margin-top: 20px;
		width: 250px;
		height: 430px;

		background-color: #f8fafc;

		.rightT {
			height: 100px;
			background-color: #aedcff;

			.ju {
				font-weight: bold;
			}

			.day {
				margin-top: 10px;
				font-weight: 500;
				line-height: 35px;

				div {
					text-align: center;
					width: 35px;
					height: 35px;
					line-height: 35px;
					background-color: #fff;
					margin: 0 8px;
					color: #aedcff;
				}
			}
		}
	}

	.kuang {
		margin-right: 10px;
		text-align: center;
		height: 30px;
		line-height: 30px;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		width: 75px;
		color: #fff;
		background-color: #aedcff;
	}

	.apply {
		padding: 0 10px;
		margin-top: 25px;

		.applyTime {
			color: #333333;
			font-weight: 500;
		}

		.applyList {
			flex-wrap: wrap;

			div {
				cursor: pointer;
				margin-top: 5px;
				margin-bottom: 5px;
				margin-right: 20px;
				color: #999;
				font-size: 12px;
			}
		}
	}

	.box {
		height: 330px;
		overflow-y: scroll;
	}

	.tiku {
		padding: 0 10px;
		margin-top: 25px;
		margin-bottom: 20px;

		.juanList {
			flex-wrap: wrap;
			justify-content: space-between;

			.juanItem {
				cursor: pointer;
				margin-top: 10px;
				// margin-right: 10px;
				width: 108px;
				height: 108px;
				border: 1px solid #ddd;
				box-sizing: border-box;

				&:nth-child(2n) {
					margin-right: 0;
				}

				.juanImg {
					margin-bottom: 10px;
					width: 25px;
					height: 30px;
					// background-color: #aedcff;
				}

				.name {
					color: #666;
				}
			}
		}
	}

	.lessons {
		padding: 55px 0;
		background-color: rgba(0, 144, 240, 0.02);
	}

	.L-title {
		justify-content: space-between;
		font-weight: 500;

		.title-l {
			font-size: 26px;
			font-weight: bold;
		}

		.title-r {
			color: #0090f0;
			cursor: pointer;
		}
	}

	.L-list {
		margin-top: 30px;
		flex-wrap: wrap;

		.L-item {
			position: relative;
			top: 0;
			margin-right: 26px;
			cursor: pointer;
			width: 280px;
			// height: 270px;
			// padding: 15px 17px 0;
			box-sizing: border-box;
			border-radius: 6px;
			overflow: hidden;
			background-color: #fff;
			transition: all 0.5s;

			.infoTop {
				position: relative;

				// height: 190px;
				// border-bottom: 1px solid #f2f2f2;
				.title {
					height: 45px;
					font-size: 20px;
					font-weight: 500;
					color: #333333;
					line-height: 30px;
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
				}

				.x_img {
					width: 100%;
					height: 150px;
					margin-bottom: 10px;
					border-radius: 5px;
				}

				.L-tags {
					height: 100px;
					overflow: hidden;

					// background-color: rgba(199, 0, 0, 0.34);
					div {
						display: inline-block;
						margin-top: 10px;
						margin-right: 10px;
						height: 24px;
						line-height: 24px;
						padding: 0 10px;
						font-size: 12px;
						color: #0090f0;

						background-color: rgba(0, 144, 240, 0.1);
					}
				}

				.period {
					position: absolute;
					// bottom: 10px;
					font-size: 12px;
					font-weight: 400;
					color: #999999;
				}
			}

			&:hover {
				position: relative;
				// top: -20px;
				box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);

				.infoBot-right1 {
					background-color: #f13232;
					color: #fff;
					box-shadow: 0px 3px 5px 0px rgba(199, 0, 0, 0.34);
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.infoBot {
		height: 60px;
		justify-content: space-between;
		font-size: 12px;
		color: #999999;
		margin: 0 17px;

		span {
			color: #f13232;
		}

		.infoBot-right {
			width: 100px;
			height: 30px;
			line-height: 30px;
			color: #f13232;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			border: 1px solid #f13232;
			border-radius: 15px 15px 15px 15px;
			box-sizing: border-box;
		}
	}

	.box::-webkit-scrollbar {
		width: 4px;
		height: 5px;
	}

	.box::-webkit-scrollbar-track {
		background: rgba(191, 191, 191, 0.4);
		border-radius: 4px;
	}

	.box::-webkit-scrollbar-thumb {
		background: rgba(191, 191, 191, 0.6);
		border-radius: 4px;
	}

	.dailog_bd::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	.dailog_bd::-webkit-scrollbar-track {
		background: rgb(239, 239, 239);
		border-radius: 2px;
	}

	.dailog_bd::-webkit-scrollbar-thumb {
		background: #bfbfbf;
		border-radius: 2px;
	}

	.dailog_bd::-webkit-scrollbar-thumb:hover {
		background: #888;
	}

	.dailog_bd::-webkit-scrollbar-corner {
		background: #179a16;
	}

	.dailog_box {
		z-index: 23;

		.mask {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 23;
			width: 100%;
			height: 100vh;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.dailog {
			position: fixed;
			top: 200px;
			left: 50%;
			transform: translate(-50%);
			z-index: 24;
			width: 620px;
			height: 573px;
			background-color: #fff;
			border-radius: 6px;
			overflow: hidden;

			.dailog_top {
				position: relative;
				width: 100%;
				font-size: 22px;
				height: 72px;
				line-height: 72px;
				text-align: center;
				border-bottom: 2px solid #ececec;

				img {
					position: absolute;
					top: 25px;
					right: 30px;
					width: 19px;
					height: 19px;
					cursor: pointer;
				}
			}

			.dailog_bd {
				height: 500px;
				padding: 30px;
				box-sizing: border-box;
				overflow: auto;

				.dai_tit {
					font-size: 18px;
					color: #0090f0;
					margin-bottom: 20px;
					font-weight: 700;

					img {
						width: 26px;
						height: 23px;
						margin-right: 8px;
						vertical-align: top;
					}
				}

				.dai_list {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 40px;

					.dai_item {
						width: 21.9%;
						// width: 120px;
						height: 30px;
						text-align: center;
						line-height: 30px;
						font-size: 14px;
						border: 1px solid #dddddd;
						border-radius: 15px;
						margin-right: 20px;
						margin-bottom: 20px;
						cursor: pointer;
					}

					.dai_item:nth-child(4n) {
						margin-right: 0;
					}
				}
			}
		}
	}

	.active3 {
		background-color: #0090f0;
		color: #fff;
	}

	.tuijian_top {
		img {
			width: 100%;
			border-radius: 5px 5px 0 0;
		}

		.tuijian_tit {
			font-size: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin: 10px 17px;
		}
	}

	.side_active {
		background-color: #0090f0;
		color: #fff;
	}

	.left_side {
		position: fixed;
		top: 50%;
		left: calc((100vw - 1200px) / 2 - 80px);
		transform: translateY(-50%);
		z-index: 33;
		width: 50px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);

		.side_item,
		.tag {
			text-align: center;
			height: 50px;
			border-bottom: 2px solid #f2f2f2;
			cursor: pointer;
			padding: 8px 10px;
			box-sizing: border-box;
			line-height: 18px;
		}

		.side_item {
			div {
				width: 30px;
				height: 38px;
				overflow: hidden;
				word-wrap: break-word;
			}
		}

		.tag {
			border-radius: 10px 10px 0 0;
		}

		.side_item:nth-child(1) {
			border-radius: 10px 10px 0 0;
		}

		.side_item:last-child {
			border: none;
			border-radius: 0 0 10px 10px;
		}
	}
</style>
<template>
	<div class="home" v-if="flag">
		<!-- 兴趣推荐弹框 -->
		<el-dialog :visible.sync="daiShow" width="0" :show-close="false">
			<div class="dailog_box">
				<!-- <div class="mask"></div> -->
				<div class="dailog">
					<div class="dailog_top">
						请选择兴趣标签
						<img @click="daiShow = !daiShow" src="../assets/img/tiku/icon_close_01.png" alt />
					</div>
					<div class="dailog_bd">
						<div v-for="(item, index) in dailogList" :key="index">
							<div class="dai_tit">
								<img :src="item.icon" alt />
								{{ item.title }}
							</div>
							<div class="dai_list">
								<div class="dai_item" v-for="(item2, index2) in item.son" :key="index2"
									:class="{ active3: active3 == item2.id }" @click="changeDai(item2.id)">
									{{ item2.title }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 报名弹框 -->
		<div class="BM">
			<el-dialog :title="BMitem.name" width="30%" :visible.sync="dialogVisible" :before-close="handleClose">
				<span v-html="BMitem.content"></span>
			</el-dialog>
		</div>
		<!-- swiper图 -->
		<div class="swiper">
			<el-carousel :interval="2000" height="500px" arrow="never" indicator-position="none">
				<el-carousel-item v-for="(item, index) in bannerList" :key="index">
					<img :src="item.litpic" alt srcset />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="all" :style="{backgroundImage:'url('+coverImg+')',backgroundSize: 'contain'}">
			<div class="one">
				<!-- <div class="w"> -->
				<div class="like">
					<!-- 兴趣标签 -->
					<div class="cannot flex2" v-if="!tagFlag">
						<div>未选择兴趣标签，无法获得个性化课程推荐</div>
						<div v-if="!user_id" @click="goLogin">登录后选择兴趣标签 >></div>
						<div v-else @click="daiShow = true">
							已登录，快去选择兴趣标签吧~
						</div>
					</div>
					<!-- 兴趣推荐 -->
					<div v-else class="lessons lessons2 w">
						<div class="w">
							<div class="L-title flex1">
								<div class="title-l">兴趣推荐</div>
								<div class="title-r" @click="goCourse">
									更多课程
									<i class="el-icon-arrow-right"></i>
								</div>
							</div>
							<div class="flex L-list">
								<div class="L-item" v-for="(item, index) in swicthList" :key="index"
									@click="goDetail($event, item.culum_id, 1)">
									<div class="tuijian_top">
										<img :src="item.alias_litpic" alt srcset />
										<div class="tuijian_tit">
											{{ item.alias_name }}
										</div>
									</div>
									<div class="flex1 infoBot">
										<div class="infoBot-left">
											已有
											<span>{{ item.alias_purchase_num }}</span>人学习
										</div>
										<div class="infoBot-right infoBot-right1">查看详情</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 最近直播 -->
				<div ref="play" class="play w" :class="`common${-2}`" v-if="playList.length > 0">
					<div class="play_header">直播预告</div>
					<div class="flex play_list">
						<div class="play_item" v-for="(item, index) in playList" :key="index">
							<!-- <div class="P_tag" v-if="item.status == 0">还未开始</div>
                <div class="P_tag P_tag1" v-if="item.status == 1">正在直播</div>
                <div class="P_tag" v-if="item.status == 2">正在直播</div>
                <div class="P_tag" v-if="item.status == 3">回播中</div>
                <div class="P_title over">{{ item.name }}</div>
                <div class="P_time">直播时间：{{ item.start_time }}</div>
                <div class="P_teacher">主讲人：{{ item.teacher }}</div> -->
							<div class="P_top">
								<div class="P_top_img">
									<img :src="item.litpic" />
								</div>
								<div class="P_title over">{{ item.name }}</div>
							</div>
							<div class="flex1 P_bottom">
								<div class="P_free">免费</div>
								<!-- <div @click="goDetail1(item.id)" class="P_detail P_detail1">查看详情</div> -->
							</div>
						</div>
					</div>
				</div>
				<!-- </div> -->
			</div>
			<!-- 建筑工程 -->
			<div class="two" v-for="(item, index) in department" :key="index"
				:class="`common${sidebarList[index + leftFlag].id}`" :ref="'hh' + index" :id="'hh' + index">
				<div class="w department">
					<div class="D_left flex">
						<div class="D_name">{{ item.name }}</div>
						<div class="flex D_Tabs">
							<div :class="{ tabItem: item.active == index1 }" @click="tab($event, index, index1)"
								v-for="(item1, index1) in item.contents" :key="index1">
								{{ item1.name }}
							</div>
						</div>
					</div>
					<div class="D_right">
						<div class="flex midRight">
							<div class="D_img">
								<img :src="item.contents[item.active].pic" alt />
							</div>
							<div class="flex mid">
								<div class="midItem" v-for="(item2, index2) in item.contents[item.active].culum"
									:key="index2" @click="goDetail($event, item2.culum_id, 1)">
									<vue-hover-mask>
										<!-- 默认插槽 -->
										<img class="itemImg" :src="item2.alias_litpic" alt srcset />
										<!-- action插槽 -->
										<template v-slot:action>
											<i class="el-icon-video-play"></i>
										</template>
									</vue-hover-mask>
									<div class="title over">{{ item2.alias_name }}</div>
									<div class="flex info">
										<div>
											已有
											<span>{{ item2.alias_purchase_num }}</span>人学习
										</div>
										<div>{{ item2.count }}课时</div>
									</div>
								</div>
							</div>
							<div class="right">
								<div class="rightT flex2">
									<div class="ju">{{ item.contents[item.active].infos }}</div>
									<div v-if="item.contents[item.active].day != 0" class="flex1 day">
										还有
										<!-- <div>0</div>
                    <div>0</div>
                    <div>1</div> -->
										<div>{{ item.contents[item.active].day }}</div>
										天
									</div>
									<div v-else class="flex1 day">
										已结束
									</div>
								</div>
								<div class="box">
									<div class="apply">
										<div class="flex1 applyTime">
											<div class="kuang">考务</div>
											<div></div>
										</div>
										<div class="flex applyList">
											<div @click="see(item3)" class="applyItem" v-for="(item3, index3) in item.contents[item.active]
                          .affairs">
												{{ item3.name }}
											</div>
											<!-- <div class="applyItem">报名时间</div>
                      <div class="applyItem">报名流程</div>
                      <div class="applyItem">报名费用</div>
                      <div class="applyItem">准考证</div>
                      <div class="applyItem">新手指南</div> -->
										</div>
									</div>
									<div class="tiku">
										<div class="kuang">题库</div>
										<div class="flex juanList">
											<div class="flex2 juanItem" v-for="(item3, index3) in tiList" :key="index3"
												@click="
                          goDetail2(
                            item.contents[item.active].categorid,
                            index3
                          )
                        ">
												<div class="juanImg">
													<img :src="item3.img" alt="" />
												</div>
												<div class="name">{{ item3.name }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 系列课 -->
			<div class="lessons" :class="`common${-1}`" v-if="xiLie.length > 0">
				<div class="w">
					<div class="L-title flex1">
						<div class="title-l">系列课</div>
						<div class="title-r" @click="goCourse">
							更多课程
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<!-- <div class="flex L-list">
            <div
              class="L-item"
              v-for="(item, index) in xiLie"
              :key="index"
              @click="goDetail($event, item.id, item.type)"
            >
              <div class="infoTop L-item-Top">
                
                <img class="x_img" :src="item.alias_litpic" alt="" />
                <div class="title over">{{ item.alias_name }}</div>
                <div class="L-tags">
                  <div v-for="(item1, index1) in item.label" :key="index1">
                    {{ item1 }}
                  </div>
                </div>
                <div class="period">{{ item.count }}课时</div>
              </div>
              <div class="flex1 infoBot L-item-Bot">
                <div class="infoBot-left">
                  已有
                  <span>{{ item.alias_purchase_num }}</span
                  >人学习
                </div>
                <div class="infoBot-right infoBot-right1">查看详情</div>
              </div>
            </div>
          </div> -->
					<div class="flex play_list">
						<div class="play_item" v-for="(item, index) in xiLie" :key="index"
							@click="goDetail($event, item.id, item.type)">
							<div class="P_top">
								<div class="P_top_img">
									<img :src="item.alias_litpic" />
								</div>
								<div class="P_title over">{{ item.alias_name }}</div>
							</div>
							<div class="flex1 P_bottom">
								<div class="P_bottom_num">
									已有
									<span>{{ item.alias_purchase_num }}</span>人学习
								</div>
								<div class="P_detail P_detail1">查看详情</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 左侧侧边栏 -->
		<div class="left_side" v-show="leftSide">
			<div class="tag" :class="{ side_active: side_active == -1 }" @mouseenter="side_active = -1"
				@mouseleave="side_active = -2" @click="goTag" v-if="tagFlag">
				兴趣推荐
			</div>
			<div class="side_item" v-for="(item, index) in sidebarList" :key="index"
				:class="{ side_active: side_active == index }" @mouseenter="side_active = index"
				@mouseleave="side_active = -2" @click="scorll(item.id, index)">
				<div>
					{{ item.name }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueHoverMask from "../components/VueHoverMask";
	import {
		banner,
		broadcast,
		indexSeries,
		indexFloor,
		leftSide,
		getswitch,
		addSwitch,
	} from "../assets/js/index";
	import {
		changeCategory
	} from "../assets/js/question";
	export default {
		name: "Home",
		components: {
			VueHoverMask,
		},
		data() {
			return {
				coverImg: "",
				flag: false,
				user_id: JSON.parse(window.localStorage.getItem("userInfo")) ?
					JSON.parse(window.localStorage.getItem("userInfo")).id :
					"",
				BMitem: {},
				leftFlag: 0,
				dialogVisible: false,
				bannerList: [],
				side_active: -2,
				active3: 0,
				sidebar: 0,
				daiShow: false,
				tagFlag: false,
				leftSide: false,
				sidebarActive: 0,
				//最近直播
				playList: [],
				//系列课
				xiLie: [],
				sidebarList: [],
				swicthList: [],
				department: [],
				dailogList: [],
				tiList: [{
						id: 1,
						img: require("../assets/img/tiku/icon_3_3.png"),
						name: "章节练习",
					},
					{
						id: 2,
						img: require("../assets/img/tiku/icon_3_8.png"),
						name: "历年真题",
					},
					{
						id: 3,
						img: require("../assets/img/tiku/icon_3_2.png"),
						name: "模拟练习",
					},
				],
			};
		},
		created() {
			this.bannerData();
			this.broadcastData();
			this.seriesData();
			this.getList();
			this.getLeft();
			this.getswitch();
			this.getCategory();
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		computed: {

			active1() {
				for (let i = 0; i < this.department.length; i++) {
					let topHeight = this.department[i];
					let bottomHeight = this.department[i + 1];
				}
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			goLogin() {
				this.$router.push({
					path: "/login",
					query: {
						active: 0,
					},
				});
			},
			// 获取顶部分类切换
			getCategory() {
				changeCategory({
					siteId: window.localStorage.getItem("siteId"),
				}).then((res) => {
					// console.log(res);
					this.dailogList = res.data.list;
				});
			},
			// 获取兴趣推荐列表
			getswitch() {
				getswitch({
					siteId: window.localStorage.getItem("siteId"),
					user_id: this.user_id,
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.flag = true;
						this.swicthList = res.data.list.data;
						this.tagFlag = res.data.list.choice == 0 ? false : true;
					}
				});
			},
			// 打开报名弹框
			see(item) {
				// console.log(item);
				this.dialogVisible = true;
				this.BMitem = item;
			},
			// 关闭报名弹框
			handleClose() {
				this.dialogVisible = false;
			},
			// 获取楼层列表
			getList() {
				indexFloor({
					siteId: window.localStorage.getItem("siteId"),
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.department = res.data;
						this.department.map((x, i) => {
							this.$set(x, "active", 0);
						});
					}
				});
			},
			// 获取左侧联动
			getLeft() {
				leftSide({
					siteId: window.localStorage.getItem("siteId"),
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.sidebarList = res.data;
					}
				});
			},
			bannerData() {
				//获取banner图
				banner({
					type: 1,
					siteId: window.localStorage.getItem("siteId"),
				}).then((res) => {
					// console.log(res);
					this.coverImg = res.data.backgroud
					this.bannerList = res.data.banner;
				});
			},
			// 获取直播列表
			broadcastData() {
				let siteId = window.localStorage.getItem("siteId");
				broadcast({
					siteId
				}).then((res) => {
					// console.log(res);
					this.playList = res.data;
					if (this.playList.length > 0) {
						this.leftFlag = 1;
					} else {
						this.leftFlag = 0;
					}
					// console.log(this.playList);
				});
			},
			seriesData() {
				let siteId = window.localStorage.getItem("siteId");
				indexSeries({
					siteId
				}).then((res) => {
					// console.log(res);
					this.xiLie = res.data;
					// console.log(res.data);
				});
			},
			// 跳转到课程首页
			goCourse() {
				this.$router.push("/course");
				window.localStorage.setItem("activeHeader", 1);
			},
			//跳转到系列课详情
			goDetail(e, id, type) {
				this.$router.push({
					path: "/detail",
					query: {
						id: id,
						active2: type
					}
				});
				window.localStorage.setItem("activeHeader", 1);
			},
			// 跳转到题库页面
			goDetail2(id, i) {
				this.$router.push({
					path: "/tiku",
					query: {
						id: id,
						active2: i + 1
					}
				});
				window.localStorage.setItem("activeHeader", 3);
			},
			//跳转到直播详情
			goDetail1(id) {
				this.$router.push({
					path: "/detail2",
					query: {
						id: id
					}
				});
				window.localStorage.setItem("activeHeader", 2);
			},
			handleScroll() {
				let scroll =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scroll >= 500) {
					this.leftSide = true;
				} else {
					this.leftSide = false;
				}
				return;
				// console.log(this.$refs.hh0);
				// this.$refs.piediv.getBoundingClientRect().top; //piediv为dom对象的ref
				let tance1 =
					document.querySelector(".play").getBoundingClientRect().top +
					window.scrollY -
					60;
				let tance2 =
					document.querySelector("#hh0").getBoundingClientRect().top +
					window.scrollY -
					60;
				let tance3 =
					document.querySelector("#hh1").getBoundingClientRect().top +
					window.scrollY -
					60;
				let tance4 =
					document.querySelector(".lessons").getBoundingClientRect().top +
					window.scrollY -
					60;

				if (this.tagFlag) {
					let tance =
						document.querySelector(".lessons2").getBoundingClientRect().top +
						window.scrollY -
						60;
					this.side_active = -1;
					if (scroll >= tance && scroll < tance1) {
						this.side_active = -1;
					}
					if (scroll >= tance1) {
						this.side_active = 0;
					}
					if (scroll >= tance2) {
						this.side_active = 1;
					}
					if (scroll >= tance3) {
						this.side_active = 2;
					}
					if (scroll == tance4) {
						this.side_active = 3;
					}

					// console.log(this.side_active);
				} else {
					if (scroll >= tance1) {
						this.side_active = 0;
					}
					if (scroll >= tance2) {
						this.side_active = 1;
					}
					if (scroll >= tance3) {
						this.side_active = 2;
					}
					if (scroll >= tance4) {
						this.side_active = 3;
					}
				}
			},
			tab(e, i, i1) {
				this.department[i].active = i1;
				// console.log(this.department[i].active)
			},
			changeDai(id) {
				this.active3 = id;
				this.daiShow = !this.daiShow;
				this.tagFlag = !this.tagFlag;
				this.side_active = -1;
				// 提交兴趣标签
				addSwitch({
					user_id: this.user_id,
					interest: id,
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.$message.success("兴趣标签选择成功");
						this.getswitch();
					}
				});
			},
			scorll(id, i) {
				this.sidebarActive = id;
				// this.side_active = i
				this.$el.querySelector(`.common${id}`).scrollIntoView({
					block: "start",
					behavior: "smooth",
					inline: "end",
				});
			},
			goTag() {
				this.$el
					.querySelector(`.lessons2`)
					.scrollIntoView({
						block: "end",
						behavior: "smooth"
					});
			},
		},
	};
</script>
